<template>
  <div class="time-avg-wrap">
    <div v-if="date" class="date">
      <b>
        {{ date }}
      </b>
    </div>
    <div class="time-info">
      <span>
        {{ daily }}
      </span>
      <span class="font-weight-medium">
        <b>{{ activityTime }}</b>
      </span>
    </div>
    <div class="avg-info">
      Avg activity
      <span class="avg-count font-weight-medium">
        {{ activityPercent }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityTimeAvg',
  props: {
    activityTime: {
      type: String,
      default: null,
    },
    activityPercent: {
      type: [String, Number],
      default: null,
    },
    daily: {
      type: String,
      default: 'Daily total',
    },
    date: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  .time-avg-wrap {
    display: flex;
    align-items: center;
    div {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .avg-info {
    .avg-count {
      color: $success;
    }
  }
</style>
