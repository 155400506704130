<script>
import { Line } from 'vue-chartjs';

export default {
  name: 'AreaChart',
  extends: Line,
  props: {
    chartData: {
      type: Array,
      default: undefined,
    },
    labelData: {
      type: Array,
      default: undefined,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gradient: null,
    };
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);
    this.gradient.addColorStop(0, 'rgba(87, 77, 146, 0.3)');
    this.gradient.addColorStop(1, 'rgba(87, 77, 146, 0)');

    this.renderChart({
      labels: this.labelData,
      datasets: [
        {
          label: 'Data One',
          borderColor: '#574D92',
          pointBackgroundColor: '#574D92',
          borderWidth: 2,
          pointBorderColor: '#574D92',
          backgroundColor: this.gradient,
          data: this.chartData,
        },
      ],
    }, this.options);
  },
};
</script>

<style scoped>

</style>
