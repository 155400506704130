export const REPORT_DAILY_HEADERS = [
  { text: 'Project', value: 'project' },
  { text: 'Member', value: 'members' },
  { text: 'Activity', value: 'activity' },
  { text: 'Time', value: 'time' },
];

export const REPORT_WEEKLY_HEADERS = [
  { text: 'Project', value: 'project' },
  { text: 'date1', value: 'date1' },
  { text: 'date2', value: 'date2' },
  { text: 'date3', value: 'date3' },
  { text: 'date4', value: 'date4' },
  { text: 'date5', value: 'date5' },
  { text: 'date6', value: 'date6' },
  { text: 'date7', value: 'date7' },
];

export const REPORT_DELETED_HEADERS = [
  { text: 'Member', value: 'member' },
  { text: 'Project', value: 'project' },
  { text: 'Activity', value: 'activity' },
  { text: 'Duration', value: 'duration' },
  { text: 'Time Span', value: 'timeSpan' },
  { text: 'Manual', value: 'manual' },
  { text: 'Reason of deleting', value: 'reasonOfDelete' },
];

export const REPORT_DATE_HEADERS = [
  { text: 'Project', value: 'project' },
  { text: 'Activity', value: 'activity' },
  { text: 'Duration', value: 'duration' },
  { text: 'Time Span', value: 'timeSpan' },
  { text: 'Manual', value: 'manual' },
  { text: 'Reason of deleting', value: 'reasonOfDelete' },
];
