<template>
  <v-row>
    <v-col md="12">
      <area-chart
        :label-data="chartData.labels"
        :chart-data="chartData.data"
        :options="chartOptions"
      />
    </v-col>
    <v-col md="12" class="mt-5">
      <activity-time-avg
        :activity-time="activityTime"
        :activity-percent="activityPercent"
        daily="Weekly total"
      />
    </v-col>
    <v-col md="12">
      <data-table
        :items="reports"
        :headers="headers"
        :custom-columns="['item.project']"
      >
        <template v-slot:custom-render-item.project="{ item }">
          <div class="d-flex align-center">
            <v-icon
              x-small
              class="dot-icon mr-2"
              :class="`success-dot`"
            >
              fiber_manual_record
            </v-icon>
            {{ item.project }}
          </div>
        </template>
      </data-table>
    </v-col>
  </v-row>
</template>

<script>
import AreaChart from '@/components/ui/Charts/AreaChart';
import ActivityTimeAvg from '@/components/collections/ActivityBar/ActivityTimeAvg';
import { REPORT_WEEKLY_HEADERS } from '@/views/Report/constants';
import DataTable from '@/components/collections/DataTable/DataTable';

export default {
  name: 'Chart',
  components: { DataTable, ActivityTimeAvg, AreaChart },
  props: {
    activityTime: {
      type: String,
      default: null,
    },
    activityPercent: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        data: [40, 39, 10, 40, 39, 80, 40],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      reports: [
        {
          id: '1',
          project: 'Test Project',
          date1: '03:37:22',
          date2: '03:37:22',
          date3: '03:37:22',
          date4: '03:37:22',
          date5: '03:37:22',
          date6: '03:37:22',
          date7: '03:37:22',
        },
        {
          id: '2',
          project: 'Test Project',
          date1: '03:37:22',
          date2: '03:37:22',
          date3: '03:37:22',
          date4: '03:37:22',
          date5: '03:37:22',
          date6: '03:37:22',
          date7: '03:37:22',
        },
        {
          id: '3',
          project: 'Test Project',
          date1: '03:37:22',
          date2: '03:37:22',
          date3: '03:37:22',
          date4: '03:37:22',
          date5: '03:37:22',
          date6: '03:37:22',
          date7: '03:37:22',
        },
      ],
      headers: REPORT_WEEKLY_HEADERS,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
